<template>
   <div class="col-12 mb-1" ref="operacao">
      <div class="card mb-0" :class="index % 2 == 1 ? '' : 'invert'">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-sm text-center text-sm-start cursor-pointer" @click="editar">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ operacao.naturezaOperacao }}</h1>
                        <p class="font-10 mb-1 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ operacao.id }}</span>
                           <span class="ms-3"><i class="far fa-exchange font-9 me-1"></i><span>{{ operacao.operacao }}</span> </span>
                        </p>
                     </div>
                     <div class="col d-none d-md-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-stream color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Finalidade:</strong>
                           <span> {{ operacao.finalidade }}</span>
                        </p>
                     </div>
                     <div class="col d-none d-md-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-envelope color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Tipo Destinatário:</strong>
                           <span> {{ operacao.tipoDestinatario }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-sm-0 mt-2 mt-sm-0" v-if="dadosUsuario.usuarioPermissoes.includes('Fiscal | Editar tributações')">
                  <div class="btn-icone color-theme">
                     <span @click="editar"><i class="far fa-pen"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Operação',
   props: ['operacao', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      editar : function () {
         if (this.dadosUsuario.usuarioPermissoes.includes('Fiscal | Editar tributações')) {
            this.$emit('editar', this.operacao)
         }
      },
      remover : function () {
         if (this.dadosUsuario.usuarioPermissoes.includes('Fiscal | Editar tributações')) {
            this.$emit('remover', this.operacao.id)
         }
      }
   }
}

</script>