<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Defina modelos de regras tributárias que vão ser utilizados na emissão de NF-e pelo sistema e NFC-e pelo caixa. Aplique esses modelos tributários em produtos na tela "Meus produtos".</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div class="col-12">
			<div class="card mb-2">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-md mb-3">
							<label><i class="far fa-calculator color-theme font-12 me-1"></i> Filtrar</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus placeholder="Busque por nome da tributação" />
                  </div>
                  <div class="col-6 col-md-3 col-lg-2 align-self-end mb-3" v-if="dadosUsuario.usuarioPermissoes.includes('Fiscal | Editar tributações')">
							<button type="button" class="btn btn-primary w-100" @click="abrirModal(null)"><i class="far fa-plus font-13 me-1"></i> Adicionar</button>
                  </div>
                  <div class="col-6 col-md-4 col-lg-3 col-xl-2 align-self-end mb-3" v-if="dadosUsuario.usuarioPermissoes.includes('Fiscal | Editar tributações')">
							<button type="button" class="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#modalOperacoes">
                        <i class="fal fa-cog font-12 me-1"></i> Gerenciar operações
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.resultado.length > 0">
			<div class="row tributacoes">
				<tributacao v-for="(tributacao, index) in pesquisa.resultado" :key="index" :tributacao="tributacao" :index="index" @editar="abrirModal($event)" />
			</div>
      </div>

      <!-- modalTributacao -->
      <div class="modal fade" id="modalTributacao" tabindex="-1" aria-labelledby="modalTributacaoLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalTributacaoLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} tributação {{ modal.id == null ? '' : ('('+ modal.nome +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<div class="row">
									<div class="col-12 mb-3">
										<label><i class="far fa-calculator color-theme font-10 me-1"></i> Nome da tributação *</label>
										<input type="text" class="form-control" v-model="modal.nome" maxlength="200" />
									</div>
									<div class="col-sm-6 mb-3 mb-sm-0">
										<label><i class="far fa-calculator color-theme font-10 me-1"></i> Origem *</label>
                              <v-select :options="listaOrigem" v-model="modal.origem" label="origem" :reduce="o => o.origem" :searchable="false" :clearable="false" placeholder="Origem">
                                 <template #option="{ origem, descricao }"><strong class="weight-500">{{ origem }}</strong><br/><small class="font-11 text-wrap">{{ descricao }}</small></template>
                                 <template v-slot:no-options>Sem resultados</template>
                              </v-select>
									</div>
									<div class="col-sm-6">
										<label class="mb-1"><i class="far fa-percentage color-theme me-1"></i> Alíquota ICMS (%) *</label> 
                              <input type="text" class="form-control" v-mask="['#.##', '##.##']" v-model="modal.aliqIcms">
									</div>
								</div>
							</div>
						</div>

                  <div class="card mb-1">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-nfe-tab" data-bs-toggle="pill" data-bs-target="#pills-nfe" type="button" role="tab" aria-controls="pills-nfe" aria-selected="true">
											<i class="far fa-file font-12"></i><span class="ms-1"> NF-e</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-nfce-tab" data-bs-toggle="pill" data-bs-target="#pills-nfce" type="button" role="tab" aria-controls="pills-nfce" aria-selected="false">
											<i class="far fa-file font-12"></i><span class="ms-1"> NFC-e</span>
										</button>
									</li>
                        </ul>
                     </div>
                  </div>

                  <div class="tab-content mb-150" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-nfe" role="tabpanel" aria-labelledby="pills-nfe-tab">
								<div class="card mb-2" v-if="modal.regrasNfe.length == 0">
									<div class="card-body py-5 text-center">
                              <i class="fal fa-folder-open font-60 text-secondary opacity-50 d-block mb-3"></i>
                              <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
									</div>
								</div>

								<regraNFe v-for="(regra, index) in modal.regrasNfe" :key="index" :regra="regra" :index="index" />
							</div>
							<div class="tab-pane fade" id="pills-nfce" role="tabpanel" aria-labelledby="pills-nfce-tab">
                        <div class="card mb-0">
                           <div class="card-body px-12 pt-12 pb-1">
                              <div class="row">
                                 <div class="col-sm-4 mb-2">
                                    <label><i class="far fa-hashtag color-theme font-10 me-1"></i> CFOP *</label>
                                    <div class="input-group">
                                       <div class="input-group-text">
                                          <i class="fal cursor-pointer" :class="modal.searchCFOP ? 'fa-undo' : 'fa-search'" @click="modal.searchCFOP = !modal.searchCFOP"
                                             :title="modal.searchCFOP ? 'Voltar' : 'Buscar'"></i>
                                       </div>
                                       <input v-if="!modal.searchCFOP" @click="modal.searchCFOP = true;" :value="modal.nfceCfop" title="Buscar CFOP" 
                                          type="text" class="form-control cursor-pointer bg-transparent" readonly placeholder="Selecione uma opção">
                                       <autoCompleteCFOP v-else class="col" :mesmoEstado="true" :apenasEntrada="false" @submit="modal.nfceCfop = $event; modal.searchCFOP = false;" />
                                    </div>
                                 </div>
                                 <div class="col-sm-4 mb-2">
                                    <label><i class="far fa-hashtag color-theme font-10 me-1"></i> {{ dadosUsuario.tipoEmpresa == 'Simples nacional' ? 'CSOSN' : 'CST Icms' }} *</label>
                                    <v-select :options="listaCSOSN" v-model="modal.nfceCsosn" label="csosn" :reduce="o => o.csosn" :searchable="false" :clearable="false" :placeholder="dadosUsuario.tipoEmpresa == 'Simples nacional' ? 'CSOSN' : 'CST Icms'">
                                       <template #option="{ csosn, descricao }"><strong class="weight-500">{{ csosn }}</strong><br/><small class="font-11 text-wrap">{{ descricao }}</small></template>
                                       <template v-slot:no-options>Sem resultados</template>
                                    </v-select>
                                 </div>
                                 <div class="col-sm-4 mb-2">
                                    <label><i class="far fa-hashtag color-theme font-10 me-1"></i> CST Pis/Cofins *</label>
                                    <v-select :options="listaCstPisCofins" v-model="modal.nfceCstPisCofins" label="cst" :reduce="o => o.cst" :searchable="false" :clearable="false" placeholder="CST">
                                       <template #option="{ cst, descricao }"><strong class="weight-500">{{ cst }}</strong><br/><small class="font-11 text-wrap">{{ descricao }}</small></template>
                                       <template v-slot:no-options>Sem resultados</template>
                                    </v-select>
                                 </div>
                                 <div class="col-12 mb-2">
                                    <label><i class="far fa-info-circle color-theme font-10 me-1"></i> Observações</label>
                                    <textarea class="form-control" v-model="modal.nfceObservacoes" maxlength="200"></textarea>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarTributacao">Salvar</button>
                  <button type="button" class="btn btn-outline-primary" v-if="modal.id" @click="imprimir">Imprimir</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalOperacoes -->
      <div class="modal fade" id="modalOperacoes" tabindex="-1" aria-labelledby="modalOperacoesLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalOperacoesLabel">Gerenciar operações</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="row">
                     <div class="col-12">
                        <div class="card mb-2">
                           <div class="card-body">
                              <h6 class="mb-0 font-14 weight-400">
											<span>{{ modalOperacoes.resultado.length }} operações encontradas</span>
											<a href="javascript:;" class="float-end font-16" @click="toggleOperacao(null)"><i class="fas fa-plus color-theme"></i></a>
										</h6>

                              <div class="w-100 my-5 text-center" v-if="modalOperacoes.resultado.length == 0">
                                 <i class="fal fa-folder-open font-60 text-secondary opacity-50 d-block mb-3"></i>
                                 <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
                              </div>
                           </div>
                        </div>
                     </div>

                     <operacao v-for="(operacao, index) in modalOperacoes.resultado" :key="index" :operacao="operacao" :index="index" 
                        @editar="toggleOperacao($event)" @remover="removerOperacao($event)" />
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalOperacao -->
      <div class="modal fade" id="modalOperacao" tabindex="-1" aria-labelledby="modalOperacaoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalOperacaoLabel">{{ modalOperacoes.operacao.id == null ? 'Adicionar' : 'Editar' }} operação</h5>
                  <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#modalOperacoes" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-5">
                     <div class="card-body">
                        <div class="row">
                           <div class="col-sm-6 mb-3">
                              <label><i class="far fa-envelope color-theme font-11 me-1"></i> Tipo destinatário *</label>
                              <v-select :options="['Fornecedor', 'Loja', 'Cliente']" v-model="modalOperacoes.operacao.tipoDestinatario" :searchable="false" :clearable="false" />
                           </div>
                           <div class="col-sm-6 mb-3">
                              <label><i class="far fa-stream color-theme font-11 me-1"></i> Finalidade *</label>
                              <v-select :options="['Normal', 'Devolução']" v-model="modalOperacoes.operacao.finalidade" :searchable="false" :clearable="false" />
                           </div>
                           <div class="col-sm-4 mb-3 mb-sm-0">
                              <label><i class="far fa-exchange color-theme font-11 me-1"></i> Operação *</label>
                              <v-select :options="['Entrada', 'Saída']" v-model="modalOperacoes.operacao.operacao" :searchable="false" :clearable="false" />
                           </div>
                           <div class="col">
                              <label><i class="far fa-hashtag color-theme font-11 me-1"></i> Natureza da operação *</label>
                              <input type="text" class="form-control" v-model="modalOperacoes.operacao.naturezaOperacao" maxlength="200" />
                           </div>
                        </div>
                     </div>
                  </div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarOperacao">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modalOperacoes">Voltar</button>
               </div>
				</div>
			</div>
		</div>

      <impressao id="impressao" class="d-none" :titulo="$route.name.replace(/_/g, ' ').replace(/-/g, '/')" :data="new Date()" :cabecalho="{
			'Nome tributação': modal.nome == null ? ' - ' : modal.nome, 
			'Origem': modal.origem == null ? ' - ' : modal.origem, 
			'Alíquota ICMS': modal.aliqIcms == null ? ' - ' : modal.aliqIcms, 
			'NFC-e - CFOP': modal.nfceCfop == null ? ' - ' : modal.nfceCfop, 
			'NFC-e - CSOSN': modal.nfceCsosn == null ? ' - ' : modal.nfceCsosn, 
			'NFC-e - CST PIS/COFINS': modal.nfceCstPisCofins == null ? ' - ' : modal.nfceCstPisCofins, 
			'NFC-e - Observações': modal.nfceObservacoes == null ? ' - ' : modal.nfceObservacoes, 
			}" :itensKey="{'tipo': 'Regras NF-e - Tipo', 'local': 'Local', 'cfop': 'CFOP', 'csosn': 'CSOSN', 'cstPisCofins': 'CST PIS/COFINS'}" 
         :itens="modal.regrasNfe" />
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import impressao from '@/components/impressao/ComprovanteA4.vue'
import regraNFe from '@/components/fiscal/tributacoes/RegraNFe.vue'
import operacao from '@/components/fiscal/tributacoes/Operacao.vue'
import tributacao from '@/components/fiscal/tributacoes/Tributacao.vue'
import autoCompleteCFOP from '@/components/fiscal/AutoCompleteCFOP.vue'

export default {
	name: 'Tributação_produto',
	data: function () {
		return {
			pesquisa: {'nome': null, 'resultado': []},
			modal: {'id': null, 'nome': '', 'origem': '0', 'aliqIcms': '', 'nfceCsosn': '', 'nfceCfop': '', 'nfceObservacoes': '', 'nfceCstPisCofins': '99', 'regrasNfe': [], 'searchCFOP': false},
         modalOperacoes: {'resultado': [], 'operacao': {'id': null, 'naturezaOperacao': '', 'operacao': 'Saída', 'finalidade': 'Normal', 'tipoDestinatario': 'Cliente'}}
		}
	},
	computed: {
      ... mapState({
         listaCstPisCofins: state => state.listaCstPisCofins,
         dadosUsuario: state => state.dadosUsuario,
         listaOrigem: state => state.listaOrigem,
         listaCSOSN: state => state.dadosUsuario.tipoEmpresa == 'Simples nacional' ? state.listaCSOSN : state.listaCstIcms,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function () {
			this.buscarTributacoes()
      },
      'pesquisa.nome' : function (val) {
         var value = val.toLowerCase()

         $(".row.tributacoes > *").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
         })
      },
      'modal.aliqIcms': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
            this.modal.aliqIcms = valor
         })
      }
   },
	components: {
		tributacao, impressao, regraNFe, operacao, autoCompleteCFOP
	},
	methods: {
      imprimir : function () {
         setTimeout(() => this.$htmlToPaper('impressao', {styles: ['./comprovanteA4.css']}), 250);
      },
		abrirModal : function (tributacao) {
			if (tributacao == null) {
				Object.assign(this.$data.modal, this.$options.data.apply(this).modal)
			} else {
				this.modal = {
               'id': tributacao.id, 
               'nome': tributacao.nome, 
               'origem': tributacao.origem, 
               'aliqIcms': tributacao.aliqIcms == null ? '' : parseFloat(tributacao.aliqIcms).toFixed(2), 
               'nfceCsosn': tributacao.nfceCsosn, 
               'nfceCfop': tributacao.nfceCfop, 
               'nfceObservacoes': tributacao.nfceObservacoes, 
               'nfceCstPisCofins': tributacao.nfceCstPisCofins,
               'regrasNfe': tributacao.regrasNfe == null || tributacao.regrasNfe.length == 0 ? this.$options.data.apply(this).modal.regrasNfe : tributacao.regrasNfe,
               'searchCFOP': false
            }
			}

			$('#modalTributacao').modal('show')
		},
		buscarTributacoes : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
			this.$store.dispatch('getTributacoes').then((data) => {
				this.pesquisa.resultado = data
			}).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
		},
      buscarOperacoes : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.$store.dispatch('getOperacoesNfe').then((data) => {
				this.modalOperacoes.resultado = data
			}).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
		},
      toggleOperacao : function (operacaoNfe) {
         if (operacaoNfe) {
            let operacao = JSON.parse(JSON.stringify(operacaoNfe))
            this.modalOperacoes.operacao = {
               'id': operacao.id, 
               'naturezaOperacao': operacao.naturezaOperacao, 
               'operacao': operacao.operacao, 
               'finalidade': operacao.finalidade, 
               'tipoDestinatario': operacao.tipoDestinatario
            }
         } else {
            this.modalOperacoes.operacao = {'id': null, 'naturezaOperacao': '', 'operacao': 'Saída', 'finalidade': 'Normal', 'tipoDestinatario': 'Cliente'}
         }

         $('#modalOperacoes').modal('hide')
         $('#modalOperacao').modal('show')
      },
		salvarTributacao : function () {
         if (this.modal.nome == null || String(this.modal.nome).trim().length == 0 ||
            this.modal.origem == null || String(this.modal.origem).trim().length == 0 ||
            this.modal.aliqIcms == null || String(this.modal.aliqIcms).trim().length == 0 ||
            this.modal.nfceCsosn == null || String(this.modal.nfceCsosn).trim().length == 0 ||
            this.modal.nfceCfop == null || String(this.modal.nfceCfop).trim().length == 0 ||
            this.modal.nfceCstPisCofins == null || String(this.modal.nfceCstPisCofins).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Preencha todos os campos obrigatórios'
            });

            return
         } else if (this.modal.regrasNfe.filter(r => r.mesmoEstadoCstPisCofins == null || String(r.mesmoEstadoCstPisCofins).trim().length == 0 || r.mesmoEstadoCsosn == null || 
            String(r.mesmoEstadoCsosn).trim().length == 0 || r.mesmoEstadoCfop == null || String(r.mesmoEstadoCfop).trim().length == 0 || r.idOperacao == null).length > 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Operações para o mesmo estado obrigatórias!'
            });

            return
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'fiscal/saveTributacao',
            headers: {'Content-Type': 'application/json'},
				data: this.modal

         }).then(() => {
            $('#modalTributacao').modal('hide')
            this.buscarTributacoes()

            this.$toast.fire({
               icon: 'success',
               title: 'Tributação salva!'
            });
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 409) {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Nome da tributação já existente!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
		},
      salvarOperacao : function () {
         if (this.modalOperacoes.operacao.naturezaOperacao == null || String(this.modalOperacoes.operacao.naturezaOperacao).trim().length == 0 ||
            this.modalOperacoes.operacao.operacao == null || String(this.modalOperacoes.operacao.operacao).trim().length == 0 ||
            this.modalOperacoes.operacao.tipoDestinatario == null || String(this.modalOperacoes.operacao.tipoDestinatario).trim().length == 0 ||
            this.modalOperacoes.operacao.finalidade == null || String(this.modalOperacoes.operacao.finalidade).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Operações para o mesmo estado obrigatórias!'
            });

            return
         }

         this.$store.dispatch('alternarTelaCarregamento', true)
         
         this.$axios({
            method: 'post',
            url: this.urlRest +'fiscal/saveOperacaoNfe',
            headers: {'Content-Type': 'application/json'},
				data: this.modalOperacoes.operacao
            
         }).then(() => {
            Object.assign(this.$data.modalOperacoes.operacao, this.$options.data.apply(this).modalOperacoes.operacao)
            $('#modalOperacao').modal('hide')
            $('#modalOperacoes').modal('show')
            
            this.buscarTributacoes()
            this.buscarOperacoes()

            this.$toast.fire({
               icon: 'success',
               title: 'Operação salva!'
            });
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 409) {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Natureza da operação já existente!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
		},
      removerOperacao : function (idOperacao) {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir operação?',
            text: 'Todas as regras vinculadas a essa operação serão excluídas.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'fiscal/deleteOperacaoNfe',
                  params: {
                     id: idOperacao
                  }
               }).then(() => {
                  this.modalOperacoes.resultado.splice(this.modalOperacoes.resultado.findIndex(o => o.id == idOperacao), 1)

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Operação excluída!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
	},
	mounted() {
      this.buscarTributacoes()
      this.buscarOperacoes()
	}
}

</script>

<style scoped>

.container-regras {
   width: 100%;
   overflow: auto;
}

.container-regras .row {
   width: max-content;
}

.container-regras {
   padding-bottom: 145px !important;
}
/* 
.container-regras,
#pills-nfce .card-body {
   padding-bottom: 145px !important;
} */

#pills-nfe input {
   padding: 7px 10px !important;
   font-size: 12px !important;
}

</style>