<template>
   <div class="card mb-1" :class="index % 2 == 1 ? 'invert': ''">
      <div class="card-body px-12 pt-12 pb-1">
         <div class="row">
            <div class="col-12 mb-2">
               <label><i class="far fa-stream color-theme font-10 me-1"></i> Operação * {{ config.operacao }}</label>
               <v-select :options="listaOperacoesNfe" v-model="regra.idOperacao" label="naturezaOperacao" :reduce="o => o.id" :searchable="false" :clearable="false">
                  <template #option="option">
                     <span class="limitador-2 text-wrap lh-sm mt-1">{{ option.naturezaOperacao }}</span>
                     <small class="d-block lh-sm mb-1 opacity-90 font-11">
                        <i class="far fa-exchange-alt font-9 me-1"></i>{{ option.operacao }}<small class="mx-2">/</small>
                        <i class="far fa-stream font-9 me-1"></i>{{ option.finalidade }}<small class="mx-2">/</small>
                        <i class="far fa-envelope font-9 me-1"></i>{{ option.tipoDestinatario }}
                     </small>
                  </template>
                  <template v-slot:no-options>
                     <span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a opção "Gerenciar operações" para adicionar</small>
                  </template>
               </v-select>
            </div>
         </div>
         <div class="row align-items-center">
            <div class="col mb-1"><hr class="mt-0 mb-1"></div>
            <div class="w-max-content mb-1"><label>Mesmo estado</label></div>
            <div class="col mb-1"><hr class="mt-0 mb-1"></div>
         </div>
         <div class="row">
            <div class="col-sm-4 mb-2">
               <label><i class="far fa-hashtag color-theme font-10 me-1"></i> CFOP *</label>
               <div class="input-group">
                  <div class="input-group-text">
                     <i class="fal cursor-pointer" :class="config.mesmoEstadoSearch ? 'fa-undo' : 'fa-search'" @click="config.mesmoEstadoSearch = !config.mesmoEstadoSearch"
                        :title="config.mesmoEstadoSearch ? 'Voltar' : 'Buscar'"></i>
                  </div>
                  <input v-if="!config.mesmoEstadoSearch" @click="config.mesmoEstadoSearch = true;" :value="regra.mesmoEstadoCfop" title="Buscar CFOP" 
                     type="text" class="form-control cursor-pointer bg-transparent" readonly placeholder="Selecione uma opção">
                  <autoCompleteCFOP v-else class="col" :mesmoEstado="true" :apenasEntrada="config.operacao ? config.operacao == 'Entrada' : null" 
                     @submit="regra.mesmoEstadoCfop = $event; config.mesmoEstadoSearch = false;" />
               </div>
            </div>
            <div class="col-sm-4 mb-2">
               <label><i class="far fa-hashtag color-theme font-10 me-1"></i> {{ dadosUsuario.tipoEmpresa == 'Simples nacional' ? 'CSOSN' : 'CST Icms' }} *</label>
               <v-select :options="listaCSOSN" v-model="regra.mesmoEstadoCsosn" label="csosn" :reduce="o => o.csosn" :searchable="false" :clearable="false" placeholder="Mesmo estado">
                  <template #option="{ csosn, descricao }"><strong class="weight-500">{{ csosn }}</strong><br/><small class="font-11 text-wrap">{{ descricao }}</small></template>
                  <template v-slot:no-options>Sem resultados</template>
               </v-select>
            </div>
            <div class="col-sm-4 mb-2">
               <label><i class="far fa-hashtag color-theme font-10 me-1"></i> CST Pis/Cofins *</label>
               <v-select :options="listaCstPisCofins" v-model="regra.mesmoEstadoCstPisCofins" label="cst" :reduce="o => o.cst" :searchable="false" :clearable="false" placeholder="Mesmo estado">
                  <template #option="{ cst, descricao }"><strong class="weight-500">{{ cst }}</strong><br/><small class="font-11 text-wrap">{{ descricao }}</small></template>
                  <template v-slot:no-options>Sem resultados</template>
               </v-select>
            </div>
         </div>
         <div class="row align-items-center">
            <div class="col mb-1"><hr class="mt-0 mb-1"></div>
            <div class="w-max-content mb-1"><label>Outro estado</label></div>
            <div class="col mb-1"><hr class="mt-0 mb-1"></div>
         </div>
         <div class="row">
            <div class="col-sm-4 mb-2">
               <label><i class="far fa-hashtag color-theme font-10 me-1"></i> CFOP</label>
               <div class="input-group">
                  <div class="input-group-text">
                     <i class="fal cursor-pointer" :class="config.outroEstadoSearch ? 'fa-undo' : 'fa-search'" @click="config.outroEstadoSearch = !config.outroEstadoSearch"
                        :title="config.outroEstadoSearch ? 'Voltar' : 'Buscar'"></i>
                  </div>
                  <input v-if="!config.outroEstadoSearch" @click="config.outroEstadoSearch = true;" :value="regra.outroEstadoCfop" title="Buscar CFOP" 
                     type="text" class="form-control cursor-pointer bg-transparent" readonly placeholder="Selecione uma opção">
                  <autoCompleteCFOP v-else class="col" :mesmoEstado="false" :apenasEntrada="config.operacao ? config.operacao == 'Entrada' : null" 
                     @submit="regra.outroEstadoCfop = $event; config.outroEstadoSearch = false;" />
               </div>
            </div>
            <div class="col-sm-4 mb-2">
               <label><i class="far fa-hashtag color-theme font-10 me-1"></i> {{ dadosUsuario.tipoEmpresa == 'Simples nacional' ? 'CSOSN' : 'CST Icms' }}</label>
               <v-select :options="listaCSOSN" v-model="regra.outroEstadoCsosn" label="csosn" :reduce="o => o.csosn" :searchable="false" :clearable="false" placeholder="Outro estado">
                  <template #option="{ csosn, descricao }"><strong class="weight-500">{{ csosn }}</strong><br/><small class="font-11 text-wrap">{{ descricao }}</small></template>
                  <template v-slot:no-options>Sem resultados</template>
               </v-select>
            </div>
            <div class="col-sm-4 mb-2">
               <label><i class="far fa-hashtag color-theme font-10 me-1"></i> CST Pis/Cofins</label>
               <v-select :options="listaCstPisCofins" v-model="regra.outroEstadoCstPisCofins" label="cst" :reduce="o => o.cst" :searchable="false" :clearable="false" placeholder="Outro estado">
                  <template #option="{ cst, descricao }"><strong class="weight-500">{{ cst }}</strong><br/><small class="font-11 text-wrap">{{ descricao }}</small></template>
                  <template v-slot:no-options>Sem resultados</template>
               </v-select>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'
import autoCompleteCFOP from '@/components/fiscal/AutoCompleteCFOP.vue'

export default {
   name: 'RegraNFe',
   props: ['regra', 'index'],
   data: function () {
      return {
         config: {'mesmoEstadoSearch': false, 'outroEstadoSearch': false, 'operacao': null}
      }
   },
   computed: {
      ... mapState({
         listaOperacoesNfe: state => state.listaOperacoesNfe,
         listaCstPisCofins: state => state.listaCstPisCofins,
         dadosUsuario: state => state.dadosUsuario,
         listaCSOSN: state => state.dadosUsuario.tipoEmpresa == 'Simples nacional' ? state.listaCSOSN : state.listaCstIcms
      })
   },
   watch: {
      'regra.idOperacao' : function (value) {
         this.verificarOperacao(value)
      }
   },
   components: {
      autoCompleteCFOP
   },
   methods: {
      verificarOperacao : function (value) {
         let objOperacao = this.listaOperacoesNfe.find(o => o.id == value)
         if (objOperacao) this.config.operacao = objOperacao.operacao
      },
      remover : function () {
         this.$emit('remover', this.index)
      }
   },
   mounted() {
      this.verificarOperacao(this.regra.idOperacao)
   }
}

</script>